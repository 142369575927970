import React, { useContext, useEffect, useRef, useState } from 'react';
import './Section.scss';
import ArticlesCarousel from 'components/shared/ArticlesCarousel/ArticlesCarousel';
import Sidebar from 'components/shared/Sidebar/Sidebar';
import LoadingContext from 'utils/LoadingContext';
import axios from 'axios';
import API, { PagesURLs } from 'utils/API';
import { Link } from 'react-router-dom';
import HelmetMetaData from 'utils/HelmetMetaData';
import EmptyPage from 'components/shared/EmptyPage/EmptyPage';
import Splide from '@splidejs/react-splide/dist/js/components/Splide';
import SplideSlide from '@splidejs/react-splide/dist/js/components/SplideSlide';

export default function Section(props) {
  let isLoading = false;
  let lastPage = false;
  const [page, setPage] = useState(1);

  // Start Config
  const { setIsLoading } = useContext(LoadingContext);

  const [firstSectionData, setFirstSectionData] = useState([]);
  const [latestArticle, setLatestArticle] = useState();

  const [sideArticles, setSideArticles] = useState([]);
  const [sideTags, setSideTags] = useState([]);
  const [sideSectionAds, setSideSectionAds] = useState({});
  const [sideSubSectionsTitle, setSideSubSectionsTitle] = useState([]);

  const detailsPartRef = useRef();

  useEffect(() => {
    window.addEventListener('scroll', handleScrollPagination);

    const section_id = props.match.params.section_id;

    const SECTION_DATA_URL = `/api/app/section-data/${section_id}?sub_sections_page=${page}&sub_sections_limit=10&articles_limit=5`;
    setIsLoading(true);
    isLoading = true;


    // Get Sections & Banner Slides
    API.get(SECTION_DATA_URL).then(async (sectionsResponse) => {
      if (sectionsResponse.data['sub_sections'].length < 10) lastPage = true;
      setFirstSectionData([...firstSectionData, ...sectionsResponse.data.sub_sections]);
      
      if (latestArticle) return;  // don't reload all apis while paginating the subsections

      setLatestArticle(sectionsResponse.data.latest_article);
      if (sectionsResponse.data.latest_article) detailsPartRef.current.innerHTML = sectionsResponse.data.latest_article?.summary_html;

      const category_id = sectionsResponse.data.latest_article?.category.id;
      const [, SIDE_ARTICLES_URL, SIDE_TAGS_URL] = PagesURLs(category_id);
      const tagsRequest = API.get(SIDE_TAGS_URL);
      const sideArticlesRequest = API.get(SIDE_ARTICLES_URL);
      const sideAds = API.get(`/api/app/section-sub-sections/${section_id}`);
      await axios
        .all([sideArticlesRequest, tagsRequest, sideAds])
        .then(
          axios.spread((...responses) => {
            const sideArticlesResponse = responses[0];
            setSideArticles(sideArticlesResponse.data.articles);

            const adsResponse = responses[2];
            setSideSubSectionsTitle(adsResponse.data.sub_sections);
            setSideSectionAds(adsResponse.data.section);

            const tagsResponse = responses[1];
            setSideTags(tagsResponse.data.tags);
          })
        );
    })
    .finally(() => {
      setIsLoading(false);
      isLoading = false;
    });

    return () => {
      window.removeEventListener('scroll', handleScrollPagination);
    };
  }, [page, setIsLoading]);

  function handleScrollPagination() {
    let scrollTop = document.scrollingElement.scrollTop;

    if (scrollTop >= document.documentElement.scrollHeight - document.documentElement.clientHeight - 700 && !isLoading && !lastPage) {
      setPage(page + 1);
    }
  }

  const renderArticleCarousel = () => {
    return firstSectionData.map((section, i) => renderCarousel(section));
  };

  const renderArticles = (section) => {
    if (section.articles) {
      return section.articles.map((article, i) => {
        return (
          <SplideSlide key={i}>
            <Link to={`/article?article_id=${article.id}`}>
              <img src={article.caption} alt="" />
              <h4>{article.section.name}</h4>
              <h3>{article.title}</h3>
              <p className="summary" dangerouslySetInnerHTML={{ __html: article.summary }}></p>
              {article.author ? (
                <div className="author">
                  <div className="author-name">
                    <img src={article.author.image} alt="" />
                    {article.author.name}
                  </div>
                  <span className="date">{article.published_date}</span>
                </div>
              ) : (
                ''
              )}
            </Link>
          </SplideSlide>
        );
      });
    }
  };
  const renderCarousel = (section) => {
    return (
      <div key={section.id} className="mb-4">
        <h2>
          <Link to={`/search?sub_section_id=${section.id}`}>{section.name}</Link>
        </h2>
        <Splide
          options={{
            type: 'slide',
            gap: '1rem',
            rewind: true,
            perPage: 2,
            perMove: 1,
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            pagination: false,
            direction: 'rtl',
            interval: 3000,
            breakpoints: {
              991: {
                perPage: 2,
              },
              767: {
                perPage: 1,
              },
            },
          }}
          // hasAutoplayProgress
        >
          {renderArticles(section)}
        </Splide>
      </div>
    );
  };

  return (
    <>
      {latestArticle ? (
        <div id="sub-section">
          <HelmetMetaData title={latestArticle ? latestArticle.title : ''}></HelmetMetaData>
          <div className="sub-section-inner">
            <div className="sub-section-inner__title">
              <h3>{latestArticle ? latestArticle.section.name : ''}</h3>
              <h2>{latestArticle ? latestArticle.title : ''}</h2>
            </div>
            <img className="sub-section-inner__poster" src={`${latestArticle ? latestArticle.caption.replace(' ', '%20') : ''}`} alt="" />
            <div className="sub-section-inner__details">
              {<p ref={detailsPartRef}></p>}
              <Link to={`/article?article_id=${latestArticle ? latestArticle.id : ''}`}>قراءة المزيد</Link>
              {latestArticle.author ? (
                <div className="author">
                  <div className="author-name">
                    <img src={latestArticle ? latestArticle.author.image : ''} alt="" />
                    {latestArticle ? latestArticle.author.name : ''}
                  </div>
                  <div>
                    <span className="date">{latestArticle ? latestArticle.published_date : ''} </span>
                    <span className="date">{latestArticle ? latestArticle.gregorian_date : ''} </span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* End Intro Slider */}
          <div className="main-body">
            {/* Start Articles Section */}
            <div className="main-body__articles">
              <div className="articles-carousel">{renderArticleCarousel()}</div>
            </div>
            {/* End Articles Section */}
            <Sidebar articles={sideArticles} tags={sideTags} sectionAds={sideSectionAds} subSectionsTitle={sideSubSectionsTitle} />
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </>
  );
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import './Article.scss';
import ArticlesCarousel from 'components/shared/ArticlesCarousel/ArticlesCarousel';
import Sidebar from 'components/shared/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
  faFolderMinus,
  faFile,
  faEye,
  faDownload,
  faThumbsUp,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faFlag,
  faFilePdf,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import LoadingContext from 'utils/LoadingContext';
import API, { PagesURLs } from 'utils/API';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import HelmetMetaData from 'utils/HelmetMetaData';

const ICONS = {
  facebook: <FontAwesomeIcon icon={faFacebookF} />,
  twitter: <FontAwesomeIcon icon={faTwitter} />,
  link: <FontAwesomeIcon icon={faLink} />,
  folder: <FontAwesomeIcon icon={faFolderMinus} />,
  file: <FontAwesomeIcon icon={faFile} />,
  watch: <FontAwesomeIcon icon={faEye} />,
  download: <FontAwesomeIcon icon={faDownload} />,
  like: <FontAwesomeIcon icon={faThumbsUp} />,
  prev: <FontAwesomeIcon icon={faAngleDoubleRight} />,
  next: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
  report: <FontAwesomeIcon icon={faExclamationTriangle} />,
  pdf: <FontAwesomeIcon icon={faFilePdf} />,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Article(props) {
  let query = useQuery();
  const article_id = query.get('article_id');
  const detailsRef = useRef();
  const summaryRef = useRef();
  // Start Config
  const { setIsLoading } = useContext(LoadingContext);

  const [articleData, setArticleData] = useState([]);
  const [sideArticles, setSideArticles] = useState([]);
  const [sideTags, setSideTags] = useState([]);
  const [emptyDetails, setEmptyDetails] = useState(false);
  const [newsDetailsPage, setNewsDetailsPage] = useState(false);
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false);

  const [showAuthorPopup, setShowAuthorPopup] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);

  const [showReportSuccess, setShowReportSuccess] = useState(false);
  const [likedArticle, setLikedArticle] = useState(false);
  const [showTranslatorBio, setShowTranslatorBio] = useState(false);
  const [nextPrevArticles, setNextPrevArticles] = useState({ next: 0, prev: 0 });
  const [currentImage, setCurrentImage] = useState('');
  const [currentAuthor, setCurrentAuthor] = useState('');
  const [sideSectionAds, setSideSectionAds] = useState({});
  const [sideSubSectionsTitle, setSideSubSectionsTitle] = useState([]);
  const [deepLink, setDeepLink] = useState('');

  const articleTitleRef = useRef(null);

  const emailRef = useRef();
  const reportEmailRef = useRef();
  const reportMessageRef = useRef();

  // Start Branch
  const createLink = (data) => {
    const fullLink = window.location.href;
    return window.branch.link(
      {
        tags: ['tag1', 'tag2'],
        channel: 'facebook',
        feature: 'dashboard',
        stage: 'new user',
        //alias: 'offers/tarek-noaman-est-et-ullamco-ex',
        data: {
          mydata: 'something',
          foo: 'bar',
          $desktop_url: fullLink,
          $android_url: fullLink,
          $ios_url: fullLink,
          $og_image_url: data.img,
          $og_title: data.title,
          $twitter_title: data.title,
          $og_description: data.description,
        },
      },
      (err, link) => {
        setDeepLink(link);
      }
    );
  };
  // End Branch

  useEffect(() => {
    let sectionId;

    const news_id = props.match.params.news_id;
    let urlPrarm = 'articles';
    switch (true) {
      case window.location.href.includes('news-unpublished'):
        urlPrarm = 'news-unpublished';
        break;

      case window.location.href.includes('articles-unpublished'):
        urlPrarm = 'articles-unpublished';
        break;

      default:
        break;
    }
    let SECTION_DATA_URL = `/api/app/${urlPrarm}/${article_id}`;
    if (news_id) {
      setNewsDetailsPage(true);
      SECTION_DATA_URL = `/api/app/news/${news_id}`;
    }

    setIsLoading(true);
    if (news_id) {
      API.get(SECTION_DATA_URL).then((newsResponse) => {
        setArticleData(newsResponse.data.news);
        createLink({
          img: newsResponse.data.news.caption,
          title: newsResponse.data.news.title,
          description: newsResponse.data.news.summary,
        });
        articleTitleRef.current.innerHTML = newsResponse.data.news.title_html.replace(/\n/g, '<br />');
        detailsRef.current.innerHTML = newsResponse.data.news.details;
        summaryRef.current.innerHTML = newsResponse.data.news.summary_html;

        if (!newsResponse.data.news.details) {
          setEmptyDetails(true);
        } else {
          setEmptyDetails(false);
        }

        // Start Get Sections & Banner Slides

        const sideArticlesRequest = API.get('/api/app/articles?limit=3&is_recommended=1');
        const tagsRequest = API.get('/api/app/tags?limit=5');

        axios.all([sideArticlesRequest, tagsRequest]).then(
          axios.spread((...responses) => {
            const sideArticlesResponse = responses[0];
            setSideArticles(sideArticlesResponse.data.articles);

            const tagsResponse = responses[1];
            setSideTags(tagsResponse.data.tags);

            setIsLoading(false);
          })
          // End Get Sections & Banner Slides
        );
      });
    } else {
      const pageName = window.location.href.includes('news-unpublished') ? 'news' : 'article';
      API.get(SECTION_DATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_token')}`,
        },
      }).then((sectionResponse) => {
        setArticleData(sectionResponse.data[pageName]);

        createLink({
          img: sectionResponse.data[pageName].caption,
          title: sectionResponse.data[pageName].title,
          description: sectionResponse.data[pageName].summary,
        });
        articleTitleRef.current.innerHTML = sectionResponse.data[pageName].title_html.replace(/\n/g, '<br />');
        setNextPrevArticles({ next: sectionResponse.data.next_article, prev: sectionResponse.data.prev_article });
        if (sectionResponse.data[pageName].liked) setLikedArticle(true);

        // Start Get Sections & Banner Slides

        summaryRef.current.innerHTML = sectionResponse.data[pageName].summary_html;
        detailsRef.current.innerHTML = sectionResponse.data[pageName].details;
        if (!sectionResponse.data[pageName].details) {
          setEmptyDetails(true);
        } else {
          setEmptyDetails(false);
        }
        if (pageName === 'news') {
          console.log('here');
          setIsLoading(false);
        } else {
          sectionId = sectionResponse.data.article?.section.id;
          const [, SIDE_ARTICLES_URL, SIDE_TAGS_URL] = PagesURLs(sectionResponse.data.article?.category.id);
          const sideArticlesRequest = API.get(newsDetailsPage ? '/api/app/articles?limit=3&is_recommended=1' : SIDE_ARTICLES_URL);
          const tagsRequest = API.get(SIDE_TAGS_URL);
          const sideAds = API.get(`/api/app/section-sub-sections/${sectionResponse.data.article?.section.id}`);
          axios.all([sideArticlesRequest, tagsRequest, sideAds]).then(
            axios.spread((...responses) => {
              const sideArticlesResponse = responses[0];
              setSideArticles(sideArticlesResponse.data.articles);

              const adsResponse = responses[2];
              setSideSubSectionsTitle(adsResponse.data.sub_sections);
              setSideSectionAds(adsResponse.data.section);

              const tagsResponse = responses[1];
              setSideTags(tagsResponse.data.tags);

              setIsLoading(false);
            })
            // End Get Sections & Banner Slides
          );
        }
      });
    }
  }, [setIsLoading, article_id, newsDetailsPage]);

  const renderTags = () => {
    if (articleData.tags) {
      return (
        <ul>
          {articleData.tags.map((tag, i) => (
            <li key={i}>
              <Link to={`/search?tag_id=${tag.id}`}>{tag.name}</Link>
            </li>
          ))}
        </ul>
      );
    }
  };
  const renderYoutube = () => {
    if (articleData.attachments) {
      return articleData.attachments.video.map((v, i) => (
        <iframe
          key={i}
          title={v.name}
          width="100%"
          height="400"
          src={v.path}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      ));
    }
  };
  const renderSoundcloud = () => {
    if (articleData.attachments) {
      return articleData.attachments.soundcloud.map((s, i) => (
        <iframe
          key={i}
          title={s.name}
          width="100%"
          height="400"
          src={s.path}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      ));
    }
  };
  const renderPDF = () => {
    if (articleData.attachments) {
      return articleData.attachments.pdf.map((p, i) => (
        <li key={i}>
          <div className="file-name">
            {ICONS.file}
            {p.name}
          </div>
          <div className="file-actions">
            <a href={p.path.view} target="_blank" rel="noopener noreferrer">
              <div className="file-actions__watch">قراءة{ICONS.watch}</div>
            </a>
            <a href={p.path.download} target="_blank" rel="noopener noreferrer" download={p.path}>
              <div className="file-actions__download">تحميل{ICONS.download}</div>
            </a>
          </div>
        </li>
      ));
    }
  };
  const renderImages = () => {
    if (articleData.attachments) {
      return articleData.attachments.image.map((p, i) => (
        <li key={i}>
          <div className="file-name">
            {ICONS.file}
            {p.name}
          </div>
          <div className="file-actions">
            <div onClick={() => openImagePopup(p.path)} className="file-actions__watch watch-image">
              مشاهدة{ICONS.watch}
            </div>
          </div>
        </li>
      ));
    }
  };
  const openImagePopup = (imageLink) => {
    setCurrentImage(imageLink);
    setShowImagePopup(true);
  };
  const renderImagePopup = () => {
    return (
      <div className="email-popup" onClick={() => setShowImagePopup(false)}>
        <div className="email-container image-container">
          <img className="attachment" src={currentImage} alt="" />
        </div>
      </div>
    );
  };
  const handleCopyURL = () => {
    var text = deepLink;
    navigator.clipboard
      .writeText(text)
      .then(
        function () {
          setCopyLinkSuccess(true);
        },
        function (err) {}
      )
      .finally(() => {
        setTimeout(() => {
          setCopyLinkSuccess(false);
        }, 2000);
      });
  };
  // Start Handle Like
  const handleLike = () => {
    if (localStorage.getItem('user_token')) {
      likeDislikeArticle();
    } else {
      setShowEmailPopup(true);
    }
  };
  // Start Submit Email
  const handleSubmitEmail = (email) => {
    setIsLoading(true);
    API.post('/api/app/generate-token', { email }).then((response) => {
      localStorage.setItem('user_token', response.data.token);
      likeDislikeArticle();
    });
  };
  // Start Submit Report
  const handleSubmitReport = (email, message) => {
    setIsLoading(true);
    API.post('/api/app/report-a-problem', { email, message, url: window.location.href }).then((response) => {
      setShowReportSuccess(true);
      setIsLoading(false);
      setTimeout(() => {
        setShowReportPopup(false);
      }, 1500);
    });
  };

  const likeDislikeArticle = () => {
    setIsLoading(true);

    API.post(
      `/api/app/articles/${article_id}/like?vote=${likedArticle ? 0 : 1}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_token')}`,
        },
      }
    ).then(() => {
      setLikedArticle(`${+likedArticle ? 0 : 1}`);
      setShowEmailPopup(false);
      setIsLoading(false);
    });
  };

  // Handle submit email on enter
  const handleKeyUp = (e, email) => {
    if (e.key === 'Enter') {
      handleSubmitEmail(email);
    }
  };

  // Start render email popup
  const renderEmailPopup = () => {
    return (
      <div className="email-popup">
        <div className="email-container">
          <h3>من فضلك قم بإدخال الإسم أو بريدك الإلكترونى حتى تتمكن من الإعجاب {newsDetailsPage ? 'بالخبر' : 'بالمحتوى'}</h3>
          <input
            type="text"
            placeholder="الإسم أو البريد الإلكتروني"
            ref={emailRef}
            onKeyUp={(event) => {
              handleKeyUp(event, emailRef.current.value);
            }}
          />
          <button onClick={() => handleSubmitEmail(emailRef.current.value)}>إرسال</button>
          <button onClick={() => setShowEmailPopup(false)}>الغاء</button>
        </div>
      </div>
    );
  };
  // Start render report popup
  const renderReportPopup = () => {
    return (
      <div className="email-popup">
        <div className="email-container">
          <h3>من فضلك قم بملئ الحقول حتي تتمكن من الإبلاغ عن المشكلة</h3>
          <input type="text" placeholder="البريد الإلكتروني" ref={reportEmailRef} />
          <textarea placeholder="الرسالة" ref={reportMessageRef}></textarea>
          <button onClick={() => handleSubmitReport(reportEmailRef.current.value, reportMessageRef.current.value)}>إرسال</button>
          <button onClick={() => setShowReportPopup(false)}>الغاء</button>
          {showReportSuccess ? <p>تم الإرسال بنجاح</p> : ''}
        </div>
      </div>
    );
  };

  // Start render Facebook button
  const renderFacebookButton = () => {
    let tagsStr = '#';
    if (articleData.tags)
      articleData.tags.map((tag, i) => {
        if (tagsStr.length < 20) {
          tagsStr = tagsStr.concat(`${tag.name}_`);
        }
      });
    tagsStr = tagsStr.replace(' ', '_').slice(0, tagsStr.length - 1);

    return (
      <FacebookShareButton url={deepLink} quote={articleData.title} hashtag={tagsStr}>
        <FacebookIcon size={50} bgStyle={{ fill: 'transparent' }} iconFillColor="#4267b2" />
      </FacebookShareButton>
    );
  };

  // Start render Facebook button
  const renderTwitterButton = () => {
    let tagsArr = [];
    if (articleData.tags) articleData.tags.map((tag, i) => tagsArr.push(`${tag.name.replace(' ', '_')}`));
    return (
      <TwitterShareButton url={deepLink} title={articleData.title} hashtags={tagsArr} via={'tafsiroqs'}>
        <TwitterIcon size={50} bgStyle={{ fill: 'transparent' }} iconFillColor="#00acee" />
      </TwitterShareButton>
    );
  };

  const renderAuthors = () => {
    return articleData.authors?.map((author, i) => {
      return (
        <div
          className="author-data"
          key={i}
          onClick={() => {
            setCurrentAuthor(author);
            setShowAuthorPopup(true);
          }}>
          <img src={author.image} alt="" />
          <div className="author-name">{author.name}</div>
        </div>
      );
    });
  };
  // Start render Author popup
  const renderAuthorPopup = () => {
    return (
      <div className="email-popup">
        <div className="email-container">
          <img src={currentAuthor.image} alt="" />
          <h3>{currentAuthor.name}</h3>
          <h5>{currentAuthor.bio}</h5>
          <button onClick={() => setShowAuthorPopup(false)}>إغلاق</button>
        </div>
      </div>
    );
  };
  return (
    <div id="article">
      <HelmetMetaData title={articleData ? articleData.title : ''}></HelmetMetaData>
      <h3>
        {articleData.section ? (
          <Link className="droid" to={`/Section/${articleData.section.id}`}>
            {articleData.section.name}
          </Link>
        ) : (
          ''
        )}
      </h3>
      <h2>
        <div style={{ fontWeight: 'bold' }} ref={articleTitleRef}></div>
        <div>
          <span className="date">{articleData.published_date}</span>
          <span className="date">{articleData.gregorian_date}</span>
        </div>
      </h2>
      <img src={articleData.caption} alt="" />
      <div className="author">
        <div className="authors">{renderAuthors()}</div>
        <div className="social-links">
          <a target="_blank" rel="noopener noreferrer" href={articleData.pdf_download}>
            <div className="pdf" title="تحميل المادة">
              {ICONS.pdf}
            </div>
          </a>
          <div title="الإبلاغ عن المادة" className="report" onClick={() => setShowReportPopup(true)}>
            {ICONS.report}
          </div>
          {
            <div title="الإعجاب بالمادة" className={`${+likedArticle ? 'liked' : ''} like`} onClick={likedArticle ? likeDislikeArticle : handleLike}>
              {ICONS.like} <span>{articleData.likes}</span>
            </div>
          }

          <div className={`${copyLinkSuccess ? 'link copied' : ''} link`} onClick={handleCopyURL}>
            {ICONS.link}
            {copyLinkSuccess ? <span>تم النسخ</span> : ''}
          </div>
          <div className="twitter">{renderTwitterButton()}</div>
          <div className="facebook">{renderFacebookButton()}</div>
        </div>
      </div>

      <div className="main-body">
        {/* Start Articles Section */}
        <div className="main-body__articles">
          {/* Start Description */}
          <div className="summary" ref={summaryRef}></div>
          <div className="description article-details-container" style={{ display: `${emptyDetails ? 'none' : 'block'}` }} ref={detailsRef}></div>
          {/* Start Attached Files */}
          {articleData.attachments?.image.length < 1 && articleData.attachments.pdf.length < 1 ? (
            ''
          ) : (
            <div className="attached-files">
              <h2>{ICONS.folder} الملفات المرفقة</h2>
              <ul>{renderImages()}</ul>
              <ul>{renderPDF()}</ul>
            </div>
          )}

          {/* Start Video */}
          {renderYoutube()}
          {/* Start SoundCloud */}
          {renderSoundcloud()}
        </div>
        {/* End Articles Section */}
        <Sidebar articles={sideArticles} tags={sideTags} sectionAds={sideSectionAds} subSectionsTitle={sideSubSectionsTitle} />
      </div>
      <div className="tags">
        {renderTags()}
        <div className="social-links">
          <a target="_blank" href={articleData.pdf_download}>
            <div className="pdf" title="تحميل المادة">
              {ICONS.pdf}
            </div>
          </a>

          <div title="الإبلاغ عن المادة" className="report" onClick={() => setShowReportPopup(true)}>
            {ICONS.report}
          </div>
          {
            <div title="الإعجاب بالمادة" className={`${+likedArticle ? 'liked' : ''} like`} onClick={likedArticle ? likeDislikeArticle : handleLike}>
              {ICONS.like} <span>{articleData.likes}</span>
            </div>
          }

          <div className={`${copyLinkSuccess ? 'link copied' : ''} link`} onClick={handleCopyURL}>
            {ICONS.link}
            {copyLinkSuccess ? <span>تم النسخ</span> : ''}
          </div>
          <div className="twitter">{renderTwitterButton()}</div>
          <div className="facebook">{renderFacebookButton()}</div>
        </div>
      </div>
      {articleData.translator ? (
        <div className="author author-bottom">
          <div className="author-data">
            <img src={articleData.translator ? articleData.translator.image : ''} alt="" />
            <div className="author-name">
              {articleData.translator ? articleData.translator.name : ''}
              {articleData.translator?.bio ? (
                <span>
                  {articleData.translator
                    ? showTranslatorBio
                      ? articleData.translator.bio + ' '
                      : articleData.translator.bio.slice(0, 20) + '...'
                    : ''}
                  {articleData.author ? (
                    <button className="more-btn" onClick={() => setShowTranslatorBio(!showTranslatorBio)}>
                      {showTranslatorBio ? 'اقل' : 'المزيد'}
                    </button>
                  ) : (
                    ''
                  )}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="next-prev">
        {nextPrevArticles.prev ? (
          <a href={`article?article_id=${nextPrevArticles.prev.id}`}>
            {ICONS.prev}
            {nextPrevArticles.prev?.title}
          </a>
        ) : (
          <div></div>
        )}
        {nextPrevArticles.next ? (
          <a href={`article?article_id=${nextPrevArticles.next.id}`}>
            {nextPrevArticles.next?.title}
            {ICONS.next}
          </a>
        ) : (
          <div></div>
        )}
      </div>
      {/* Start Email Popup */}
      {showAuthorPopup ? renderAuthorPopup() : ''}
      {showEmailPopup ? renderEmailPopup() : ''}
      {showReportPopup ? renderReportPopup() : ''}
      {showImagePopup ? renderImagePopup() : ''}
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import './Contact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faYoutube, faSoundcloud, faPeriscope } from '@fortawesome/free-brands-svg-icons';
import API from './../../utils/API';
import LoadingContext from 'utils/LoadingContext';
import { CountriesList } from './CountriesList';
import HelmetMetaData from 'utils/HelmetMetaData';
const ICONS = {
  facebook: <FontAwesomeIcon icon={faFacebookF} />,
  twitter: <FontAwesomeIcon icon={faTwitter} />,
  youtube: <FontAwesomeIcon icon={faYoutube} />,
  telegram: <FontAwesomeIcon icon={faPaperPlane} />,
  soundcloud: <FontAwesomeIcon icon={faSoundcloud} />,
  periscope: <FontAwesomeIcon icon={faPeriscope} />,
};
export default function Contact() {
  const contactInfoRef = useRef(null);
  const { setIsLoading } = useContext(LoadingContext);

  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailedErrors, setSendFailedErrors] = useState([]);
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    message: '',
  });
  const [socialLinks, setSocialLinks] = useState();
  useEffect(() => {
    setIsLoading(true);
    async function getContactInfo() {
      const result = await API.get('/api/app/contact-info');
      contactInfoRef.current.insertAdjacentHTML('afterbegin', result.data.location.address);
      setSocialLinks(result.data.social_links);
      setIsLoading(false);
    }
    getContactInfo();
  }, [setIsLoading]);

  const handleChanges = (type, evt) => {
    setContactFormData({ ...contactFormData, [type]: evt.target.value });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    API.post('/api/app/contact-us', contactFormData)
      .then(() => {
        setSendFailedErrors([]);
        setSendSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setSendSuccess(false);
        const errors = [];
        for (const key in error.response.data.errors) {
          errors.push(error.response.data.errors[key][0]);
        }

        setSendFailedErrors(errors);
        setIsLoading(false);
      });
  };
  const getSendFailedErrors = () => {
    const errElements = (
      <ul className="send-failed">
        <span>فشل الإرسال للأسباب الاتيه</span>
        {sendFailedErrors.map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    );
    return errElements;
  };

  const renderSocialLinks = (socialEl, url) => {
    return (
      <a target="_blank" rel="noopener noreferrer" className={socialEl} href={url.includes('https') ? url : 'https://' + url}>
        {ICONS[socialEl]}
      </a>
    );
  };
  const renderCountiresList = () => {
    return CountriesList.map((country, i) => (
      <option value={country} key={i}>
        {country}
      </option>
    ));
  };

  return (
    <div id="contact">
      <HelmetMetaData title={'اتصل بنا'}></HelmetMetaData>
      <h2>إتصل بنا</h2>
      <div className="contact-body">
        {/* Start Form */}
        <div className="contact-body__form">
          <form onSubmit={handleSubmit}>
            <h2>نموذج الإتصال</h2>
            <input type="text" placeholder="الإسم" onChange={(e) => handleChanges('name', e)} />
            <input type="text" placeholder="البريد الإلكتروني" onChange={(e) => handleChanges('email', e)} />
            <input type="text" placeholder="الجوال" onChange={(e) => handleChanges('phone', e)} />
            <select defaultValue={'DEFAULT'} onChange={(e) => handleChanges('country', e)}>
              <option value="DEFAULT" disabled>
                الدولة
              </option>
              {renderCountiresList()}
            </select>
            <textarea
              cols="30"
              rows="10"
              placeholder="الرسالة"
              value={contactFormData.message}
              onChange={(e) => handleChanges('message', e)}></textarea>
            <button type="submit">إرسال</button>
            {sendSuccess ? <span className="send-success">تم الإرسال بنجاح</span> : ''}
            {+sendFailedErrors.length > 0 ? getSendFailedErrors() : ''}
          </form>
        </div>

        {/* Start Description */}
        <div className="contact-body__description" ref={contactInfoRef}>
          <br />
          <br />
          <div className="social-links">
            {socialLinks && socialLinks.periscope ? renderSocialLinks('periscope', socialLinks.periscope) : ''}
            {socialLinks && socialLinks.soundcloud ? renderSocialLinks('soundcloud', socialLinks.soundcloud) : ''}
            {socialLinks && socialLinks.telegram ? renderSocialLinks('telegram', socialLinks.telegram) : ''}
            {socialLinks && socialLinks.youtube ? renderSocialLinks('youtube', socialLinks.youtube) : ''}
            {socialLinks && socialLinks.twitter ? renderSocialLinks('twitter', socialLinks.twitter) : ''}
            {socialLinks && socialLinks.facebook ? renderSocialLinks('facebook', socialLinks.facebook) : ''}
          </div>
        </div>
      </div>
      {/* Start Map */}
      <iframe
        title="our location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3621.2848068662265!2d46.646717!3d24.819932000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee3282fcf33d5%3A0x2cec75cbf1812d32!2z2YXYsdmD2LIg2KrZgdiz2YrYsSDZhNmE2K_Ysdin2LPYp9iqINin2YTZgtix2KLZhtmK2Kk!5e0!3m2!1sen!2sus!4v1602004428504!5m2!1sen!2sus"
        width="100%"
        height="500"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"></iframe>
    </div>
  );
}

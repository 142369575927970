import React from 'react';
import './Centers.scss';
import Chapter from 'layouts/Chapter/Chapter';
import HelmetMetaData from 'utils/HelmetMetaData';
import { PagesURLs } from 'utils/API';

export default function Centers() {
  const pageNumber = '3';
  const [SECTIONS_URL, SIDE_ARTICLES_URL, SIDE_TAGS_URL] = PagesURLs(pageNumber);

  return (
    <>
      <HelmetMetaData title={'مراكز و مؤسسات'}></HelmetMetaData>
      <Chapter SECTIONS_URL={SECTIONS_URL} SIDE_ARTICLES_URL={SIDE_ARTICLES_URL} SIDE_TAGS_URL={SIDE_TAGS_URL} />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import './SocialMedia.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faYoutube, faSoundcloud, faPeriscope } from '@fortawesome/free-brands-svg-icons';
import API from 'utils/API';
const ICONS = {
  facebook: <FontAwesomeIcon icon={faFacebookF} />,
  twitter: <FontAwesomeIcon icon={faTwitter} />,
  youtube: <FontAwesomeIcon icon={faYoutube} />,
  telegram: <FontAwesomeIcon icon={faPaperPlane} />,
  soundcloud: <FontAwesomeIcon icon={faSoundcloud} />,
  periscope: <FontAwesomeIcon icon={faPeriscope} />,
  arrow: <FontAwesomeIcon icon={faAngleRight} />,
};
export default function SocialMedia() {
  const [socialLinks, setSocialLinks] = useState();
  const [showSocialLinks, setShowSocialLinks] = useState(false);
  useEffect(() => {
    async function getContactInfo() {
      const result = await API.get('/api/app/contact-info');
      setSocialLinks(result.data.social_links);
    }
    getContactInfo();
  }, []);

  const renderSocialLinks = (socialEl, url) => {
    return (
      <a target="_blank" rel="noopener noreferrer" className={socialEl} href={url.includes('https') ? url : 'https://' + url}>
        {ICONS[socialEl]}
      </a>
    );
  };

  return (
    <div className="hide-sm" id="social-media">
      <ul className={showSocialLinks ? 'hide' : ''}>
        <li>{socialLinks && socialLinks.facebook ? renderSocialLinks('facebook', socialLinks.facebook) : ''}</li>
        <li>{socialLinks && socialLinks.twitter ? renderSocialLinks('twitter', socialLinks.twitter) : ''}</li>
        <li>{socialLinks && socialLinks.youtube ? renderSocialLinks('youtube', socialLinks.youtube) : ''}</li>
        <li>{socialLinks && socialLinks.telegram ? renderSocialLinks('telegram', socialLinks.telegram) : ''}</li>
        <li>{socialLinks && socialLinks.soundcloud ? renderSocialLinks('soundcloud', socialLinks.soundcloud) : ''}</li>
        <li>{socialLinks && socialLinks.periscope ? renderSocialLinks('periscope', socialLinks.periscope) : ''}</li>
        <li className={showSocialLinks ? 'show' : ''} onClick={() => setShowSocialLinks(!showSocialLinks)}>
          {ICONS.arrow}
        </li>
      </ul>
      <ul className={!showSocialLinks ? 'hide' : ''}>
        <li className={showSocialLinks ? 'show' : ''} onClick={() => setShowSocialLinks(!showSocialLinks)}>
          {ICONS.arrow}
        </li>
      </ul>
    </div>
  );
}

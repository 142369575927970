import React from 'react';
import './Spinner.scss';
export default function Spinner() {
  return (
    <section className="preloader">
      <div className="preloader__wrapper">
        <div className="preloader__circle">
          <div className="preloader__circle-line preloader__circle-line--1"></div>
          <div className="preloader__circle-line preloader__circle-line--2"></div>
          <div className="preloader__circle-line preloader__circle-line--3"></div>
          <div className="preloader__circle-line preloader__circle-line--4"></div>
          <div className="preloader__circle-line preloader__circle-line--5"></div>
          <div className="preloader__circle-line preloader__circle-line--6"></div>
          <div className="preloader__circle-line preloader__circle-line--7"></div>
          <div className="preloader__circle-line preloader__circle-line--8"></div>
          <div className="preloader__circle-line preloader__circle-line--9"></div>
        </div>
      </div>
    </section>
  );
}

import React, { useRef, useEffect, useContext, useState } from 'react';
import './About.scss';
import LoadingContext from 'utils/LoadingContext';
import API from 'utils/API';
import HelmetMetaData from 'utils/HelmetMetaData';

const scrollToRef = (ref) => {
  window.scrollTo({ top: document.getElementById(ref).offsetTop - 300, behavior: 'smooth' });
};

export default function About(props) {
  const { setIsLoading } = useContext(LoadingContext);
  const [detailsArr, setDetailsArr] = useState([]);
  const detailsRef = useRef(null);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  useEffect(() => {
    setIsLoading(true);
    API.get(props.url).then((result) => {
      const data = result.data.content.sections;
      setDetailsArr(data);

      for (let i = 0; i < data.length; i++) {
        detailsRef.current.insertAdjacentHTML(
          'beforeend',
          `<div id='section${i}'>
            <h2>${data[i].title}</h2>
            ${data[i].details}
          </div>`
        );
      }
      setIsLoading(false);
    });
  }, [setIsLoading, props.url]);
  function handleScroll() {
    const scrollTop = document.scrollingElement.scrollTop;
    const currentEL = document.getElementsByClassName('current')[0];

    for (let i = 0; i < detailsArr.length; i++) {
      window[`section${i}Interval`] =
        document.getElementById(`section${i}`).offsetTop + document.getElementById(`section${i}`).offsetHeight / 2 - 300;
    }
    if (currentEL) {
      currentEL.classList.remove('current');
    }
    for (let i = 0; i < detailsArr.length; i++) {
      const el = document.querySelector(`.about-body__sidebar > ul > li:nth-child(${i + 1})`);
      const activeElements = document.getElementsByClassName('current');
      switch (true) {
        case scrollTop <= `${eval(`section${i}Interval`)}`:
          if (el && activeElements.length < 1) {
            el.classList.add('current');
          }
          break;

        default:
          break;
      }
    }
  }

  const getSidebar = () => {
    return detailsArr.map((el, i) => (
      <li key={i} id={'section' + i + 'link'} onClick={() => scrollToRef('section' + i)}>
        {el.title}
      </li>
    ));
  };
  return (
    <div id="about">
      <HelmetMetaData title={`${props.url.includes('share') ? 'شارك بقلمك' : 'من نحن'}`}></HelmetMetaData>
      <h2>من نحن</h2>
      <div className="about-body">
        <div className="about-body__sidebar">
          <ul>{getSidebar()}</ul>
        </div>
        <div className="about-body__description" ref={detailsRef}></div>
      </div>
    </div>
  );
}

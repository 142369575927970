import React, { useContext, useEffect, useState, useRef } from 'react';
import './Chapter.scss';
import ArticlesCarousel from 'components/shared/ArticlesCarousel/ArticlesCarousel';
import Sidebar from 'components/shared/Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import LoadingContext from 'utils/LoadingContext';
import API from 'utils/API';
import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/react-splide';

export default function Chapter(props) {
  // Start Config
  const { setIsLoading } = useContext(LoadingContext);

  const [sections, setSections] = useState([]);
  const [firstSectionData, setFirstSectionData] = useState([]);

  const [sideArticles, setSideArticles] = useState([]);
  const [sideTags, setSideTags] = useState([]);

  const ref = useRef()
  useEffect(() => {
    const autoPlay = setInterval(() => {
        ref.current.splide.go('>');
      }, 3000);
     // clearing interval
    return () => clearInterval(autoPlay);
  });

  useEffect(() => {
    setIsLoading(true);
    const sectionsRequest = API.get(props.SECTIONS_URL);

    const sideArticlesRequest = API.get(props.SIDE_ARTICLES_URL);
    const tagsRequest = API.get(props.SIDE_TAGS_URL);
    // Get Sections & Banner Slides
    axios.all([sectionsRequest, sideArticlesRequest, tagsRequest]).then(
      axios.spread((...responses) => {
        const sectinsResponse = responses[0];
        setSections(sectinsResponse.data.sections);
        setFirstSectionData(sectinsResponse.data.first_section_data);

        const sideArticlesResponse = responses[1];
        setSideArticles(sideArticlesResponse.data.articles);

        const tagsResponse = responses[2];
        setSideTags(tagsResponse.data.tags);

        setIsLoading(false);
      })
    );
  }, [setIsLoading]);

  const renderSectionsCarousel = () => {
    return (
      <Splide ref={ref}
        className="intro-slider"
        options={{
          type: 'slide',
          gap: '1rem',
          rewind: true,
          perPage: 3,
          perMove: 1,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          pagination: false,
          direction: 'rtl',
          interval: 3000,
          breakpoints: {
            991: {
              perPage: 2,
            },
            767: {
              perPage: 1,
            },
          },
        }}
        hasAutoplayProgress>
        {sections.map((section, i) => {
          return (
            <SplideSlide key={i}>
              <Link style={{ backgroundImage: `url(${section.caption})` }} to={`/Section/${section.id}`}>
                <span>{section.name}</span>
              </Link>
            </SplideSlide>
          );
        })}
      </Splide>
    );
  };
  const renderArticleCarousel = () => {
    return firstSectionData.map((section, i) => <ArticlesCarousel key={i} data={section} />);
  };
  return (
    <div id="section">
      <div id="section__intro">{renderSectionsCarousel()}</div>
      {/* End Intro Slider */}
      <div className="main-body">
        {/* Start Articles Section */}
        <div className="main-body__articles">{renderArticleCarousel()}</div>
        {/* End Articles Section */}
        <Sidebar articles={sideArticles} tags={sideTags} />
      </div>
    </div>
  );
}

import React from 'react';
import Chapter from 'layouts/Chapter/Chapter';
import HelmetMetaData from 'utils/HelmetMetaData';
import { PagesURLs } from 'utils/API';

export default function Collections() {
  const pageNumber = '4';
  const [SECTIONS_URL, SIDE_ARTICLES_URL, SIDE_TAGS_URL] = PagesURLs(pageNumber);

  return (
    <>
      <HelmetMetaData title={'منوعات'}></HelmetMetaData>
      <Chapter SECTIONS_URL={SECTIONS_URL} SIDE_ARTICLES_URL={SIDE_ARTICLES_URL} SIDE_TAGS_URL={SIDE_TAGS_URL} />
    </>
  );
}

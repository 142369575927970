import React from 'react';
import './Center.scss';
import Sidebar from 'components/shared/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMosque } from '@fortawesome/free-solid-svg-icons';
import ArticlesCarousel from 'components/shared/ArticlesCarousel/ArticlesCarousel';

const ICONS = {
  mosque: <FontAwesomeIcon icon={faMosque} />,
};
export default function () {
  return (
    <div id="center">
      <div className="center-body">
        {ICONS.mosque}
        <div className="center-body__details">
          <h3>المركز</h3>
          <h2>إسم المركز</h2>
          <p>
            هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص
            الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي
            لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل
            كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت"
          </p>
          <span>المملكة السعودية</span>
        </div>
      </div>
      <div className="main-body">
        {/* Start Articles Section */}
        <div className="main-body__articles">
          <ArticlesCarousel />
          <ArticlesCarousel />
          <ArticlesCarousel />
        </div>
        {/* End Articles Section */}
        <Sidebar />
      </div>
    </div>
  );
}

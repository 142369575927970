import React from 'react';
import './EmptyPage.scss';

export default function EmptyPage() {
  return (
    <div>
      <h3>لا يوجد بيانات للعرض</h3>
    </div>
  );
}

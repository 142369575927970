import React, { useContext, useEffect, useState, useRef } from 'react';
import './Footer.scss';
import footerImg1 from 'helpers/images/footer/1.png';
import footerImg2 from 'helpers/images/footer/2.png';
import footerImg3 from 'helpers/images/footer/3.png';
import footerImg4 from 'helpers/images/footer/4.png';
import logo from 'helpers/images/footer/logo-footer.png';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';
import LoadingContext from 'utils/LoadingContext';
import API from 'utils/API';
export default function Footer() {
  const [email, setEmail] = useState();
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailedErrors, setSendFailedErrors] = useState([]);
  const { setIsLoading } = useContext(LoadingContext);

  const [sponsor, setSponsor] = useState({ link: '', logo: '' });
  const [externalWebsiteLinks, setExternalWebsiteLinks] = useState([]);
  const footerSplideRef = useRef()

  useEffect(() => {
    API.get('/api/app/external-website-links')
      .then((response) => {
        setExternalWebsiteLinks(response.data.records);
      });

    async function getContactInfo() {
      const result = await API.get('/api/app/contact-info');
      setSponsor(result.data.sponsor);
    }
    getContactInfo();

    const autoPlay = setInterval(() => {
      footerSplideRef.current.splide.go('>');
    }, 2000);
    // clearing interval
    return () => clearInterval(autoPlay);
  }, []);

  const renderExternalWebisteLinks = () => {
    return (
      <>
        <Splide ref={footerSplideRef}
          options={{
            type: 'slide',
            gap: '1rem',
            arrows: false,
            rewind: true,
            perPage: 4,
            perMove: 1,
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            pagination: false,
            direction: 'rtl',
            interval: 3000,
            breakpoints: {
              991: {
                perPage: 3,
              },
              767: {
                perPage: 2,
              },
              576: {
                perPage: 1,
              },
            },
          }}>
          {externalWebsiteLinks.map((externalWebsite, i) => {
            return (
              <SplideSlide key={i}>
                <a href={externalWebsite.link} target="_blank" rel="noopener noreferrer">
                  <img src={externalWebsite.image} alt="external-website-logos" />
                </a>
              </SplideSlide>
            );
          })}
        </Splide>
      </>
    );
  };

  const handleSubscribe = () => {
    setIsLoading(true);
    API.post('/api/app/subscribe', { email, path: window.location.pathname + window.location.search })
      .then((response) => {
        setSendFailedErrors([]);
        setSendSuccess(true);
      })
      .catch((error) => {
        setSendSuccess(false);
        const errors = [];
        for (const key in error.response.data.errors) {
          errors.push(error.response.data.errors[key][0]);
        }

        setSendFailedErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          setSendFailedErrors([]);
          setSendSuccess(false);
        }, 2000);
      });
  };
  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSubscribe();
    }
  };
  const getSendFailedErrors = () => {
    const errElements = (
      <ul className="send-failed">
        {sendFailedErrors.map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    );
    return errElements;
  };
  return (
    <footer>
      <div className="container">
        <div className="subscribe">
          <img src={logo} alt="" onClick={() => (window.location.href = '/')} />
          <div>
            <input
              type="text"
              placeholder="القائمة البريدية"
              onKeyUp={(event) => {
                setEmail(event.target.value);
                handleKeyUp(event);
              }}
            />
            <button onClick={handleSubscribe}>اشترك</button>
            {sendSuccess ? <span className="send-success">تم الإشتراك بنجاح</span> : ''}
            {+sendFailedErrors.length > 0 ? getSendFailedErrors() : ''}
          </div>
          {sponsor ? (
            <a href={sponsor.link} target="_blank" rel="noopener noreferrer">
              <img src={sponsor.logo} alt="" />
            </a>
          ) : (
            ''
          )}
        </div>
        <div className="logos">
          {renderExternalWebisteLinks()}
          {/* <a href="https://tafsir.net/" target="_blank" rel="noopener noreferrer">
            <img src={footerImg4} alt="" />
          </a>
          <a href="https://vb.tafsir.net/" target="_blank" rel="noopener noreferrer">
            <img src={footerImg3} alt="" />
          </a>
          <a href="https://tafsiracademy.com/" target="_blank" rel="noopener noreferrer">
            <img src={footerImg2} alt="" />
          </a>
          <a href="https://modoee.com/" target="_blank" rel="noopener noreferrer">
            <img src={footerImg1} alt="" />
          </a> */}
        </div>
        <div className="copyright">
          <p>
            Powered by{' '}
            <a href="https://nawah.ai" target="_blank">
              Nawah.ai
            </a>
          </p>
          <div className="pipe hide-sm">|</div>
          <p>جميع الحقوق محفوظة لمركز تفسير للدراسات القرآنية © 2021</p>
        </div>
      </div>
    </footer>
  );
}

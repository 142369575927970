import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import './Home.scss';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Sidebar from 'components/shared/Sidebar/Sidebar';
import ArticlesCarousel from 'components/shared/ArticlesCarousel/ArticlesCarousel';
import LoadingContext from 'utils/LoadingContext';
import API from 'utils/API';
import HelmetMetaData from 'utils/HelmetMetaData';
import { Link } from 'react-router-dom';
export default function Home() {
  // Start Config
  const { setIsLoading } = useContext(LoadingContext);

  const [sectionsArr, setSectionsArr] = useState([3, 4, 6, 7]);
  const [slides, setSlides] = useState([1, 2]);
  const [showArticles, setShowArticles] = useState(false);

  const [sideArticles, setSideArticles] = useState([]);
  const [sideTags, setSideTags] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);
  const [latestNews, setLatestNews] = useState([]);

  const MenuLinks = {
    1: { path: '/issues', name: 'قضايا ومعالجات' },
    2: { path: '/books', name: 'كتب ومصنفات' },
    3: { path: '/centers', name: 'مراكز ومؤسسات' },
    4: { path: '/collections', name: 'منوعات' },
    5: { path: '/meetings', name: 'لقاءات المرصد' },
    6: { path: '/news', name: 'نشرات' },
    7: { path: '/about', name: 'من نحن' },
    8: { path: '/contact', name: 'اتصل بنا' },
  };
  useEffect(() => {
    setIsLoading(true);
    const sectionsRequest = API.get('/api/app/home-page-data?limit=5');
    const bannerRequest = API.get('/api/app/banner-slides');
    const sideArticlesRequest = API.get('/api/app/articles?limit=3&is_recommended=1');
    const tagsRequest = API.get('/api/app/tags?limit=5');
    // Get Sections & Banner Slides
    axios.all([sectionsRequest, bannerRequest, sideArticlesRequest, tagsRequest]).then(
      axios.spread((...responses) => {
        const sectinsResponse = responses[0];
        setSectionsArr(sectinsResponse.data.categories);
        setLatestArticles(sectinsResponse.data.latest_articles);
        setLatestNews(sectinsResponse.data.latest_news);
        const bannerResponse = responses[1];
        setSlides(bannerResponse.data.slides);
        setShowArticles(true);

        const sideArticlesResponse = responses[2];
        setSideArticles(sideArticlesResponse.data.articles);

        const tagsResponse = responses[3];
        setSideTags(tagsResponse.data.tags);

        setIsLoading(false);
      })
    );
  }, [setIsLoading]);

  const renderBannerCarousel = () => {
    return (
      <Splide
        className="intro-slider"
        options={{
          type: 'slide',
          gap: '1rem',
          rewind: true,
          perPage: 1,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          direction: 'rtl',
          interval: 3000,
        }}
        hasAutoplayProgress>
        {slides.map((slide, i) => {
          return (
            <SplideSlide key={i}>
              <Link to={slide.type == 'article' ? `/article?article_id=${slide.id}` : `/news/${slide.id}`}>
                <img className="intro-slide" src={slide.caption} title={slide.title} alt={slide.title} />
              </Link>
            </SplideSlide>
          );
        })}
      </Splide>
    );
  };
  // End Config

  // Chapter Carousels
  const renderChaptersCarousel = () => {
    return sectionsArr.map((section, i) => {
      return (
        <div className="articles-carousel" key={i}>
          <h2>
            <Link to={MenuLinks[section.id].path}>{section.name}</Link>{' '}
          </h2>
          <Splide
            className="intro-slider"
            options={{
              type: 'slide',
              gap: '1rem',
              rewind: true,
              perPage: 2,
              perMove: 1,
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              pagination: false,
              direction: 'rtl',
              interval: 3000,
              breakpoints: {
                991: {
                  perPage: 2,
                },
                767: {
                  perPage: 1,
                },
              },
            }}>
            {/*  */}
            {section.articles.map((article, i) => {
              return (
                <SplideSlide key={i}>
                  <Link to={`/article?article_id=${article.id}`}>
                    <img src={article.caption} alt="" />
                    <h4>{article.section.name}</h4>
                    <h3>{article.title}</h3>
                    <p className="summary" dangerouslySetInnerHTML={{ __html: article.summary }}></p>
                    {article.author ? (
                      <div className="author">
                        <div className="author-name">
                          <img src={article.author.image} alt="" />
                          {article.author.name}
                        </div>
                        <span className="date">{article.published_date}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Link>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      );
    });
  };

  // Render Latest Articles
  const renderLatestArticlesCarousel = () => {
    return (
      <Splide
        options={{
          type: 'slide',
          gap: '1rem',
          rewind: true,
          perPage: 2,
          perMove: 1,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          pagination: false,
          direction: 'rtl',
          interval: 3000,
          breakpoints: {
            991: {
              perPage: 2,
            },
            767: {
              perPage: 1,
            },
          },
        }}>
        {latestArticles.map((article, i) => {
          return (
            <SplideSlide key={i}>
              <Link to={`/article?article_id=${article.id}`}>
                <img src={article.caption} alt="" />
                <h4>{article.section?.name}</h4>
                <h3>{article.title}</h3>
                <p className="summary" dangerouslySetInnerHTML={{ __html: article.summary }}></p>
                {article.author ? (
                  <div className="author">
                    <div className="author-name">
                      <img src={article.author.image} alt="" />
                      {article.author.name}
                    </div>
                    <span className="date">{article.published_date}</span>
                  </div>
                ) : (
                  ''
                )}
              </Link>
            </SplideSlide>
          );
        })}
      </Splide>
    );
  };
  // Render Latest News
  const renderLatestNewsCarousel = () => {
    return (
      <>
        <h2>
          <a href="/news">أحدث النشرات</a>
        </h2>
        <Splide
          options={{
            type: 'slide',
            gap: '1rem',
            rewind: true,
            perPage: 2,
            perMove: 1,
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            pagination: false,
            direction: 'rtl',
            interval: 3000,
            breakpoints: {
              991: {
                perPage: 2,
              },
              767: {
                perPage: 1,
              },
            },
          }}>
          {latestNews.map((news, i) => {
            return (
              <SplideSlide key={i}>
                <Link to={`/news/${news.id}`}>
                  <img src={news.caption} alt="" />
                  {/* <h4>{article.section.name}</h4> */}
                  <h3>{news.title}</h3>
                  <div className="author">
                    <div className="author-name">
                      {/* <img src={article.author.image} alt="" /> */}
                      {news.author ? news.author : ''}
                    </div>
                    <span className="date">{news.published_date}</span>
                  </div>
                </Link>
              </SplideSlide>
            );
          })}
        </Splide>
      </>
    );
  };
  return (
    <>
      <HelmetMetaData title={'مرصد تفسير | الصفحة الرئيسية'}></HelmetMetaData>
      {/* Start Intro Slider */}
      {renderBannerCarousel()}
      {/* End Intro Slider */}
      <div className="main-body">
        {/* Start Articles Section */}
        <div className="main-body__articles">
          <div className="articles-carousel">
            <h2>الأحدث إضافة</h2>
            {showArticles ? renderLatestArticlesCarousel() : ''}
          </div>
          {showArticles ? renderChaptersCarousel() : ''}
          <div className="articles-carousel">{showArticles && latestNews.length ? renderLatestNewsCarousel() : ''}</div>
        </div>
        {/* End Articles Section */}
        <Sidebar articles={sideArticles} tags={sideTags} />
      </div>
    </>
  );
}

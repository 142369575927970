import axios from 'axios';
import EmptyPage from 'components/shared/EmptyPage/EmptyPage';
import Sidebar from 'components/shared/Sidebar/Sidebar';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import API from 'utils/API';
import HelmetMetaData from 'utils/HelmetMetaData';
import LoadingContext from 'utils/LoadingContext';
import './Search.scss';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let lastSearchTerm = '';
export default function Search(props) {
  let isLoading = false;
  let lastPage = false;
  const [page, setPage] = useState(1);

  let query = useQuery();
  const search_term = query.get('search_term');
  const tag_id = query.get('tag_id');
  const sub_section_id = query.get('sub_section_id');
  // Start Config
  const { setIsLoading } = useContext(LoadingContext);
  const [allElements, setAllElements] = useState([]);
  const [newsPage, setNewsPage] = useState(false);
  const [tagsTitle, setTagsTitle] = useState('');

  const [sideArticles, setSideArticles] = useState([]);
  const [sideTags, setSideTags] = useState([]);
  useEffect(() => {
    const sideArticlesRequest = API.get('/api/app/articles?limit=3&is_recommended=1');
    const tagsRequest = API.get('/api/app/tags?limit=5');
    axios.all([sideArticlesRequest, tagsRequest]).then(
      axios.spread((...responses) => {
        const sideArticlesResponse = responses[0];
        setSideArticles(sideArticlesResponse.data.articles);

        const tagsResponse = responses[1];
        setSideTags(tagsResponse.data.tags);

        setIsLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    if (props.news) setNewsPage(true);

    window.addEventListener('scroll', handleScrollPagination);
    getSearchElements();
    return () => {
      window.removeEventListener('scroll', handleScrollPagination);
    };
  }, [page, newsPage, search_term]);

  function handleScrollPagination() {
    let scrollTop = document.scrollingElement.scrollTop;

    if (scrollTop > document.documentElement.scrollHeight - document.documentElement.clientHeight - 500 && !isLoading && !lastPage) {
      setPage(page + 1);
    }
  }
  const getSearchElements = () => {
    setIsLoading(true);
    isLoading = true;
    let API_URL = '';
    let RESPONSE_ARRAY = '';
    switch (true) {
      case !!search_term:
        API_URL = `/api/app/articles/search?page=${page}&limit=20&q=${search_term}`;
        RESPONSE_ARRAY = 'categories';
        break;
      case !!tag_id:
        API_URL = `/api/app/articles?page=${page}&limit=20&tag_id=${tag_id}&grouped_by=category`;
        RESPONSE_ARRAY = 'categories';
        break;
      case !!sub_section_id:
        API_URL = `/api/app/articles?page=${page}&limit=20&sub_section_id=${sub_section_id}`;
        RESPONSE_ARRAY = 'articles';
        break;
      case newsPage:
        API_URL = `/api/app/news?page=${page}&limit=20`;
        RESPONSE_ARRAY = 'news';
        break;

      default:
        break;
    }
    if (API_URL) {
      API.get(API_URL).then((response) => {
        if (response.data[RESPONSE_ARRAY].length === 0) lastPage = true;
        if (response.data.title) setTagsTitle(response.data.title);

        setAllElements([...allElements, ...response.data[RESPONSE_ARRAY]]);
        lastSearchTerm = search_term;
        setIsLoading(false);
        isLoading = false;
      });
    }
  };
  const renderElements = () => {
    return (
      <ul>
        {allElements.map((element, i) => (
          <li key={i}>
            <Link to={newsPage ? `news/${element.id}` : `/article?article_id=${element.id}`}>
              <img src={element.caption} alt={element.title} />
              {newsPage ? '' : <h4>{element.section.name}</h4>}
              <h3>{element.title}</h3>
              <p className="summary" dangerouslySetInnerHTML={{ __html: element.summary }}></p>
              {element.author ? (
                <div className="author">
                  <div className="author-name">
                    <img src={element.author.image} alt={element.bio} />
                    {element.author.name}
                  </div>
                  <span className="date">{element.published_date}</span>
                </div>
              ) : (
                ''
              )}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  const renderSearchSections = () => {
    return allElements.map((category, i) => {
      return (
        <div key={i}>
          <h2>{category.name}</h2>
          <ul>
            {category.articles.map((element, i) => (
              <li key={i}>
                <Link to={newsPage ? `news/${element.id}` : `/article?article_id=${element.id}`}>
                  <img src={element.caption} alt="" />
                  {newsPage ? '' : <h4>{element.section.name}</h4>}
                  <h3>{element.title}</h3>
                  {element.author ? (
                    <div className="author">
                      <div className="author-name">
                        {newsPage ? '' : <img src={element.author.image} alt="" />}
                        {newsPage ? element.author : element.author.name}
                      </div>
                      <span className="date">{element.published_date}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      );
    });
  };
  return (
    <>
      <div className="main-body">
        <div className="main-body__articles">
          <HelmetMetaData title={`${newsPage ? 'نشرات' : `${search_term ? `بحث | ${search_term}` : ''}`}`}></HelmetMetaData>
          {search_term ? (
            <>
              <h2 className="droid">بحث : {search_term}</h2>
              <div className="separator"></div>
            </>
          ) : (
            ''
          )}
          {window.location.href.includes('sub_section_id') ? (
            <>
              <h2 className="droid">{allElements[0]?.sub_section.name}</h2>
              <div className="separator"></div>
            </>
          ) : (
            ''
          )}
          {tag_id ? (
            <>
              <h2 className="droid"> {tagsTitle}</h2>
              <div className="separator"></div>
            </>
          ) : (
            ''
          )}
          <div id="elements-container">{search_term || tag_id ? renderSearchSections() : renderElements()}</div>
          {/* Empty Data */}
          {allElements.length ? '' : <EmptyPage />}
        </div>
        <Sidebar articles={sideArticles} tags={sideTags} />
      </div>
    </>
  );
}

import ReactGA from 'react-ga';
import React, { useState, useEffect } from 'react';
import './App.scss';
import Header from 'components/shared/Header/Header';
import Footer from 'components/shared/Footer/Footer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from 'pages/Home/Home';
import Contact from 'pages/Contact/Contact';
import About from 'pages/About/About';
import Meetings from 'pages/Meetings/Meetings';
import Collections from 'pages/Collections/Collections';
import Centers from 'pages/Centers/Centers';
import Books from 'pages/Books/Books';
import Issues from 'pages/Issues/Issues';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Section from 'layouts/Section/Section';
import Article from 'layouts/Article/Article';
import Center from 'pages/Centers/Center/Center';
import { LoadingProvider } from 'utils/LoadingContext';
import Spinner from 'components/shared/Spinner/Spinner';
import ScrollToTop from 'utils/ScrollToTop';
import Search from 'pages/Search/Search';
import HelmetMetaData from 'utils/HelmetMetaData';
import SocialMedia from 'components/shared/SocialMedia/SocialMedia';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const loadingConfig = { isLoading, setIsLoading };

  useEffect(() => {
    ReactGA.initialize('UA-181402523-1');
    ReactGA.pageview(window.location.pathname + (window.location.pathname == '/search' ? '' : window.location.search));
  });

  return (
    <Router>
      <ScrollToTop />
      <LoadingProvider value={loadingConfig}>
        {/* <HelmetMetaData title={'hello'}></HelmetMetaData> */}
        <Header />
        <SocialMedia />
        <div className={`container router-outlet ${loadingConfig.isLoading ? 'loading' : ''}`}>
          <Route exact path="/" component={Home} />
          <Route path="/issues" component={Issues} />
          <Route path="/books" component={Books} />
          <Route path="/centers" component={Centers} />
          <Route path="/center" component={Center} />
          <Route path="/collections" component={Collections} />
          <Route path="/meetings" component={Meetings} />
          <Route path="/about" render={(props) => <About url="/api/app/pages/about-us" {...props} />} />
          <Route path="/share-your-pen" render={(props) => <About url="/api/app/pages/share-your-pen" {...props} />} />
          <Route path="/contact" component={Contact} />
          <Route path="/Section/:section_id" component={Section} />
          <Route path="/article" component={Article} />
          <Route path="/search" component={Search} />
          <Route path="/news" exact={true} render={(props) => <Search news {...props} />} />
          <Route path="/news/:news_id" component={Article} />
          <Route path="/guest-admin/articles-unpublished" component={Article} />
          <Route path="/guest-admin/news-unpublished" component={Article} />
        </div>
        <Footer />
        {loadingConfig.isLoading ? <Spinner /> : ''}
      </LoadingProvider>
    </Router>
  );
}

export default App;

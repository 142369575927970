import React, { useContext, useEffect, useRef, useState } from 'react';
import './Header.scss';
import logo from 'helpers/images/logo.png';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch, faFeatherAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from 'components/shared/ProgressBar/ProgressBar';
import LoadingContext from 'utils/LoadingContext';
import { useHistory } from 'react-router';
import API from 'utils/API';

const ICONS = {
  arrow_dowwn: <FontAwesomeIcon icon={faChevronDown} />,
  search: <FontAwesomeIcon icon={faSearch} />,
  shopping: <FontAwesomeIcon icon={faShoppingCart} />,
  feather: <FontAwesomeIcon icon={faFeatherAlt} />,
};
const MenuLinks = [
  { path: '/issues', name: 'قضايا ومعالجات', id: 1 },
  { path: '/books', name: 'كتب ومصنفات', id: 2 },
  { path: '/centers', name: 'مراكز ومؤسسات', id: 2 },
  { path: '/collections', name: 'منوعات', id: 4 },
  { path: '/meetings', name: 'لقاءات المرصد', id: 5 },
  { path: '/news', name: 'نشرات', id: 6 },
  { path: '/about', name: 'من نحن', id: 7 },
  { path: '/contact', name: 'اتصل بنا', id: 8 },
];

function Header() {
  const history = useHistory();

  const headerRef = useRef(null);
  const searchRef = useRef(null);
  const [oldScrollTop, setOldScrollTop] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuId, setSubMenuId] = useState(0);
  const [activeArticleListId, setActiveArticleListId] = useState(0);
  const [subMenuData, setSubMenuData] = useState([]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', onClickOutsideListener);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  useEffect((params) => {
    API.get('/api/app/navbar-data?sections_limit=4&articles_limit=3').then((response) => {
      setSubMenuData(response.data.categories);
    });
  }, []);
  function handleScroll() {
    let scrollTop = document.scrollingElement.scrollTop;
    if (scrollTop >= oldScrollTop && scrollTop > 90) {
      headerRef.current.classList.add('sticky');
    } else {
      headerRef.current.classList.remove('sticky');
    }
    setOldScrollTop(scrollTop);
  }

  const RenderMenuLinks = () => {
    return MenuLinks.map((link, i) => {
      return (
        <li
          key={i}
          onClick={() => {
            setShowSubMenu(false);
            setSubMenuId(0);
          }}
          onMouseEnter={() => {
            setSubMenuId(i + 1);
            setShowSubMenu(true);
            setActiveArticleListId(0);
          }}>
          <NavLink to={link.path} className={subMenuId === i + 1 ? 'active droid' : 'droid'}>
            {link.name}
          </NavLink>
        </li>
      );
    });
  };
  const getLoadingContext = useContext(LoadingContext);
  const handleSearch = (searchTerm) => {
    let search_history = JSON.parse(localStorage.getItem('search_history'));
    if (search_history) {
      search_history.unshift(searchTerm);
      localStorage.setItem('search_history', JSON.stringify(search_history));
    } else {
      search_history = [];
      search_history.unshift(searchTerm);
      localStorage.setItem('search_history', JSON.stringify(search_history));
    }
    window.location.href = '/';
    window.location.href = `/search?search_term=${searchTerm}`;
  };

  const handleKeyUp = (e, searchTerm) => {
    setShowSearchHistory(false);
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const deleteSearchHistory = (i) => {
    const modefiedArray = JSON.parse(localStorage.getItem('search_history'));
    const removed = modefiedArray.splice(i, 1);
    localStorage.setItem('search_history', JSON.stringify(modefiedArray));
    setShowSearchHistory(false);
    setTimeout(() => {
      setShowSearchHistory(true);
    }, 1);
  };
  const renderSearchHistory = () => {
    const search_history = JSON.parse(localStorage.getItem('search_history'));
    return (
      <div className="search-element__history">
        <ul>
          {search_history && search_history.length ? (
            search_history.map((el, i) => (
              <li key={i}>
                <span onClick={() => deleteSearchHistory(i)}>x</span>
                <Link
                  onClick={() => {
                    searchRef.current.value = search_history[i];
                    setShowSearchHistory(false);

                    window.location.href = `/search?search_term=${search_history[i]}`;
                  }}
                  to={'/'}>
                  {el}
                </Link>
              </li>
            ))
          ) : (
            <li>لا يوجد تاريخ بحث</li>
          )}
        </ul>
      </div>
    );
  };
  const onClickOutsideListener = (e) => {
    if (!e.target.closest('#searchContainer')) setShowSearchHistory(false);
    // document.removeEventListener('click', onClickOutsideListener);
  };

  const renderSubMenu = () => {
    const submenu = subMenuData.filter((el) => el.id === subMenuId);

    if (showSubMenu && subMenuId < 6 && submenu[0])
      return (
        <div className="submenu container">
          <div className="submenu-sections">
            <ul>
              {submenu[0].sections.map((section, i) => (
                <li className={activeArticleListId === i ? 'active' : ''} key={i} onMouseEnter={() => setActiveArticleListId(i)}>
                  <a href={`/Section/${section.id}`} className="droid">
                    {section.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="submenu-articles">
            <ul>
              {submenu[0].sections[activeArticleListId]?.articles.map((article, i) => {
                return (
                  <Link
                    key={i}
                    onClick={() => {
                      setShowSubMenu(false);
                      setSubMenuId(0);
                    }}
                    to={`/article?article_id=${article.id}`}>
                    <li>
                      {<img src={article.caption} alt="" />} {article.title}
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      );
  };
  return (
    <header ref={headerRef}>
      <div className="container">
        {/* Start Top Bar */}
        <div className="top-bar">
          <Link to="/">
            <img src={logo} alt="Marsad Logo" />
          </Link>
          <div className="search">
            <div className="search-element " id="searchContainer">
              <button onClick={() => setShowSearchHistory(!showSearchHistory)}>{ICONS.arrow_dowwn}</button>
              <input
                className={searchFocus ? 'focus droid' : 'droid'}
                type="text"
                placeholder="ابحث"
                onFocus={() => {
                  setSearchFocus(true);
                  setShowSearchHistory(false);
                }}
                onBlur={() => setSearchFocus(false)}
                ref={searchRef}
                onKeyUp={(event) => handleKeyUp(event, searchRef.current.value)}
              />
              <span onClick={() => handleSearch(searchRef.current.value)}>{ICONS.search}</span>
              {showSearchHistory ? renderSearchHistory() : ''}
            </div>
            <button className="store-tafsir hide-sm">
              <a href="https://tafsirstore.net" target="_blank" rel="noopener noreferrer" className="droid">
                متجر تفسير {ICONS.shopping}
              </a>
            </button>
          </div>
          <button className="show-sm menu-button" onClick={() => setShowMenu(!showMenu)}>
            <div id="nav-icon2" className={showMenu ? 'open' : ''}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
      </div>
      {/* Start Menu */}
      <div
        onMouseLeave={() => {
          setShowSubMenu(false);
          setSubMenuId(0);
        }}
        className={showMenu ? 'menu show-menu' : 'menu'}>
        <div className="container">
          <ul id="main-menu">
            {RenderMenuLinks()}
            {renderSubMenu()}
          </ul>
          <button className="show-sm store-tafsir droid">متجر تفسير {ICONS.shopping}</button>
          <button className="participate">
            <Link to="/share-your-pen" className="droid">
              {ICONS.feather} شارك بقلمك
            </Link>
          </button>
        </div>
      </div>
      {getLoadingContext.isLoading ? <ProgressBar /> : ''}
    </header>
  );
}

export default Header;

import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import { Link } from 'react-router-dom';
import API from 'utils/API';

export default function Sidebar(props) {
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);
  const [liveAd, setLiveAd] = useState([]);

  const [sideSectionAds, setSideSectionAds] = useState({});
  const [sideSubSectionsTitle, setSideSubSectionsTitle] = useState([]);

  useEffect(() => {
    setArticles(props.articles);
    setTags(props.tags);
    setSideSectionAds(props.sectionAds);
    setSideSubSectionsTitle(props.subSectionsTitle);
    API.get('/api/app/live-link').then((response) => {
      setLiveAd(response.data);
    });
  }, [props.tags]);

  const renvderLiveAd = () => {
    return (
      <>
        <a href={liveAd.live_link} target="_blank" rel="noopener noreferrer">
          <div className="sidebar__ad" style={{ backgroundImage: `url(${liveAd.live_image})` }}></div>
        </a>
      </>
    );
  };

  const renderSideArticles = () => {
    if (articles.length) {
      return (
        <div className="sidebar__articles">
          <h3 className="droid">نرشح لك</h3>
          {articles.map((article, i) => (
            <Link key={i} to={`/article?article_id=${article.id}`}>
              <div className="sidebar__articles--article">
                {/* <div className="img" style={{ backgroundImage: `url(${article.caption})` }}></div> */}
                <div className="img">
                  <img src={article.caption} alt="" />
                </div>
                <h4>{article.section.name}</h4>
                <h3>{article.title}</h3>
              </div>
            </Link>
          ))}
        </div>
      );
    }
  };

  const renderSideTags = () => {
    if (tags.length) {
      return (
        <>
          <h3 className="droid">البحث فى الموضوعات</h3>
          <div className="sidebar__tags">
            <ul>
              {tags.map((tag, i) => {
                return (
                  <li key={i}>
                    <Link onClick={() => (window.location.href = `/search?tag_id=${tag.id}`)} to={'/'}>
                      {tag.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      );
    }
  };
  const renderSunSectionsTitle = () => {
    if (sideSubSectionsTitle && sideSubSectionsTitle.length) {
      return (
        <div className="sidebar__tags full-width">
          <ul>
            {sideSubSectionsTitle.map((title, i) => {
              return (
                <li key={i}>
                  <Link to={`/search?sub_section_id=${title.id}`}>{title.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  };

  const renderSideSectionAds = () => {
    if (sideSectionAds) {
      return (
        <div className="sidebar__articles">
          <a href={`/Section/${sideSectionAds.id}`} target="_blank" rel="noopener noreferrer">
            <h3 className="droid">{sideSectionAds.name}</h3>
            <img className="sidebar__ad article" src={sideSectionAds.caption} alt="" />
          </a>
        </div>
      );
    }
  };
  return (
    <div className="sidebar">
      {renvderLiveAd()}
      {/* Start Ads Section - Articles Part */}
      {renderSideArticles()}

      {/* End Ads Section - Articles Part */}
      {renderSideTags()}
      {/* Start Main Section Ads */}
      {renderSideSectionAds()}
      {/* Start Sub Sections Title */}
      {renderSunSectionsTitle()}
    </div>
  );
}

import axios from 'axios';

export default axios.create({
  baseURL: 'https://api.tafsiroqs.com',
  responseType: 'json',
});

export const PagesURLs = (pageNumber) => {
  const SECTIONS_URL = `/api/app/category-page-data/${pageNumber}`;
  const SIDE_ARTICLES_URL = `/api/app/articles?limit=3&is_recommended=1&category_id=${pageNumber}`;
  const SIDE_TAGS_URL = `/api/app/tags?limit=5&category_id=${pageNumber}`;

  return [SECTIONS_URL, SIDE_ARTICLES_URL, SIDE_TAGS_URL];
};

import React, { useEffect, useState, useRef } from 'react';
import './ArticlesCarousel.scss';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';
export default function ArticlesCarousel(props) {
  const [section, setSection] = useState({});
  useEffect(() => {
    setSection(props.data);
  }, [props.data]);

  const ref = useRef()
  useEffect(() => {
    const autoPlay = setInterval(() => {
        ref.current.splide.go('>');
      }, 3000);
     // clearing interval
    return () => clearInterval(autoPlay);
  });

  const renderArticles = () => {
    if (section.articles) {
      return section.articles.map((article, i) => {
        return (
          <SplideSlide key={i}>
            <Link to={`/article?article_id=${article.id}`}>
              <img src={article.caption} alt="" />
              <h4>{article.section.name}</h4>
              <h3>{article.title}</h3>
              <p className="summary" dangerouslySetInnerHTML={{ __html: article.summary }}></p>
              {article.author ? (
                <div className="author">
                  <div className="author-name">
                    <img src={article.author.image} alt="" />
                    {article.author.name}
                  </div>
                  <span className="date">{article.published_date}</span>
                </div>
              ) : (
                ''
              )}
            </Link>
          </SplideSlide>
        );
      });
    }
  };
  const renderCarousel = () => {
    return (
      <>
        <h2>
          <Link to={`/search?sub_section_id=${section.id}`}>{section.name}</Link>
        </h2>
        <Splide ref={ref}
          options={{
            type: 'slide',
            gap: '1rem',
            rewind: true,
            perPage: 2,
            perMove: 1,
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            pagination: false,
            direction: 'rtl',
            interval: 3000,
            breakpoints: {
              991: {
                perPage: 2,
              },
              767: {
                perPage: 1,
              },
            },
          }}
          // hasAutoplayProgress
        >
          {renderArticles()}
        </Splide>
      </>
    );
  };
  return <div className="articles-carousel">{section ? renderCarousel() : ''}</div>;
}
